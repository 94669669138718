/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import qs from 'query-string'
import Home from '../Home'
import Header from '../../components/Header'
import NoMatch from '../../components/NoMatch'
import Loader from '../../components/Loader'
import { AppName, SearchInterval } from '../../constants'
import { searchTracks } from '../../redux/modules/search/actions'
import { currentlyPlayingTrack, onTrackPlayed, onTrackPaused } from '../../redux/modules/tracks/actions'
import Footer from '../../components/Footer';

class SiteRouter extends Component {  
  constructor(props) {
    super(props)
    this.state = {
      onTrackPlay: this.onTrackPlay,
      selectedTrack: {},
      searchTimeout: 0
    }
    this.onSearch = this.onSearch.bind(this)
    this.onTrackPlay = this.onTrackPlay.bind(this)
    this.playNextTrack = this.playNextTrack.bind(this)
    this.playPreviousTrack = this.playPreviousTrack.bind(this)
    this.onTrackPlayed = this.onTrackPlayed.bind(this)
    this.onTrackPaused = this.onTrackPaused.bind(this)
    this.onTrackEnd = this.onTrackEnd.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props
    const { location: { search } } = nextProps
    const { q } = qs.parse(search)
           
    if(this.props.location.search !== search) {
      dispatch(searchTracks(q))  
    }    
  }

  onSearch = (value) => {
    const { history } = this.props
    clearTimeout(this.state.searchTimeout)    
    const searchTimeout = setTimeout(()=>{
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        history.push(`?q=${value}`)
    }, SearchInterval)  

    this.setState({
      searchTimeout: searchTimeout
    })
  }

  onTrackPlay = (trackInfo) => {
    const { dispatch } = this.props
    dispatch(currentlyPlayingTrack(trackInfo))      
  }

  getIndexOfTrack = (trackId) => {
    const { tracks:{ list = [] } = {}} = this.props
    for(let i=0; i<list.length; i++) {
      const { id } = list[i]
      if(id === trackId) {
        return i;
      }
    }    
  }

  playNextTrack = () => {
    const { tracks: { list = [], playingTrack } = {}} = this.props
    const nextTrackIndex = this.getIndexOfTrack(playingTrack.id) + 1     
    if(nextTrackIndex < list.length) {
      this.onTrackPlay(list[nextTrackIndex])
    }    
  }
  
  onTrackEnd = () => {
    this.onTrackPaused()
    this.playNextTrack()
  }
  
  playPreviousTrack = () => {
    const { tracks: { list = [], playingTrack } = {}} = this.props
    const nextTrackIndex = this.getIndexOfTrack(playingTrack.id) - 1
    if(nextTrackIndex >= 0) {
      this.onTrackPlay(list[nextTrackIndex])
    }         
  }

  onTrackPlayed = () => {
    const { dispatch } = this.props
    dispatch(onTrackPlayed())
  }
  
  onTrackPaused = () => {
    const { dispatch } = this.props
    dispatch(onTrackPaused())
  }

  render() {
    const { search:{ value } = {}} = this.state
    const { configuration:{ 
              service: { client_id } = {}
            } = {}, 
            tracks: { 
              isFetching,
              playingTrack: {                 
                title,
                stream_url='',
                user: { username, avatar_url } = {} 
              } = {}
            } = {}
          } = this.props
    return (
      <div>
        <Header 
          text={AppName}
          searchValue={value}
          onSearch={this.onSearch}
          isFetching={isFetching}
        />
        <Loader isLoading={isFetching} />
        <Router>
          <main>
            <Switch>               
              <Route exact path="/" render={() => <Home {...this.state}/> } />                  
              <Route component={NoMatch} />
            </Switch>   
          </main> 
        </Router>
        <Footer 
          title={title}
          stream_url={stream_url}
          artist={username}
          client_id={client_id}
          avatar={avatar_url}
          playNextTrack={this.playNextTrack}
          playPreviousTrack={this.playPreviousTrack}
          onTrackPlayed={this.onTrackPlayed}
          onTrackPaused={this.onTrackPaused}
          onTrackEnd={this.onTrackEnd}
        />           
      </div>      
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state    
  }
}

export const ConnectedSite = connect(mapStateToProps)(SiteRouter)
export default withRouter(ConnectedSite)