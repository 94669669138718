
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


const secondsToTime = (timeSeconds) => {
    timeSeconds = timeSeconds/1000
    let hours   = Math.floor(timeSeconds / 3600)
    let minutes = Math.floor((timeSeconds - (hours * 3600)) / 60)
    let seconds = parseInt((timeSeconds - (hours * 3600) - (minutes * 60)))
    let time = ""

    if (hours != 0) {
      time = hours+":"
    }
    if (minutes != 0 || time !== "") {
      minutes = (minutes < 10 && time !== "") ? "0"+minutes : String(minutes)
      time += minutes+":"
    } 
    if (time === "") {
      time = seconds+"s"
    }
    else {
      time += (seconds < 10) ? "0"+seconds : String(seconds)
    }
    return time
}

const TrackCard = ({
    title,
    artist,
    avatar,
    duration,
    imageUrl,
    onClick,
    likesCount,
    repostsCount,
    isSelected,
    isSelectedTrackPaused
}) => {     
    if(!title) return null
    return (         
            <div>
                <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-card-body uk-override">
                    <div className="uk-card-media-top cursor" onClick={onClick}> 
                        <div>
                            {
                                isSelected ? 
                                    (
                                        isSelectedTrackPaused ? 
                                        <div className="lds-ripple uk-position-center lds-ripple-pause position-z-index-1"></div> 
                                        : <div className="lds-ripple uk-position-center position-z-index-1"><div></div><div></div></div>
                                    )
                                    : null
                            }
                            <div className="playBtnContainer">
                                <img 
                                    className="primary-image uk-height-medium"
                                    data-src={imageUrl} 
                                    alt={title}
                                    style={{width: '100%', maxHeight: '150px', objectFit: 'cover'}}
                                    uk-cover={true}
                                    uk-img="target: !.uk-slideshow-items"
                                />
                                {
                                    isSelected? <div className={'pause_btn'}><img /></div>
                                        :<div className={'play_btn'}><img /></div>
                                }
                                
                            </div>                                                         
                            <div className={classNames({
                               'track-card-active-paused': isSelected && isSelectedTrackPaused,
                               'track-card-active': isSelected && !isSelectedTrackPaused
                            })}></div>                                                   
                        </div>                                               
                    </div>
                    <div className="uk-flex card-body">
                        <div className=''>
                            <img className='card-avatar' src={avatar} />
                        </div>   
                        <div className='card-details'>
                            <div className='card-title' alt={title}>{title}</div>
                            <a className="uk-link-text card-title">{artist}</a>  
                        </div>                         
                    </div> 
                    <div className="uk-flex uk-margin-top uk-align-center uk-text-center uk-margin-top">
                        <div className='card-details'>
                            <div className="card-heart"></div>
                            <div>{likesCount}</div>                                
                        </div>
                        <div className='card-details'>
                            <div className="card-repost"></div>
                            <div>{repostsCount}</div>                                
                        </div>                        
                        <div className='card-details'>
                            <div className="card-clock"></div>
                            <div>{secondsToTime(duration)}</div>                                
                        </div>
                    </div>                    
                </div>  
            </div>                               
    )
}

TrackCard.defaultProps = {
    artist: '',
    avatar: '',
    isSelected: false,
    isSelectedTrackPaused: false    
}

TrackCard.propTypes = {    
    artist: PropTypes.string,
    avatar: PropTypes.string,
    isSelected: PropTypes.bool,
    isSelectedTrackPaused: PropTypes.bool,
    title: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    likesCount: PropTypes.number.isRequired,
    repostsCount: PropTypes.number.isRequired    
}

export default TrackCard