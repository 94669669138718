/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BottomScrollListener from 'react-bottom-scroll-listener'
import qs from 'query-string'
import TrackCard from '../../components/Card/TrackCard'
import Loader from '../../components/Loader'

import {
    fetchTracks,
    fetchNextTracks
} from '../../redux/modules/tracks/actions'

class Home extends Component {
    constructor(props) {
        super(props)       
        this.fetchTracksIfNeeded = this.fetchTracksIfNeeded.bind(this)
        this.onClick = this.onClick.bind(this)
    }
    componentDidMount(){              
        const { location: { search } = {} } = this.props  
        const { q } = qs.parse(search)
        this.fetchTracksIfNeeded(q)
    }

    componentWillReceiveProps(nextProps) {                
        const { location: { search } } = nextProps
       
        if(this.props.location.search !== search) {
            const { q } = qs.parse(search)
            this.fetchTracksIfNeeded(q)
        }       
    }
        
    fetchTracksIfNeeded(query){
        const { dispatch, tracks={} } = this.props
        if(!tracks.isFetching) {
            dispatch(fetchTracks(query))    
        }
    }

    onPageEnd = () => {        
        const { dispatch } = this.props
        dispatch(fetchNextTracks())
    }
    
    onClick=(track)=>{                            
        const { onTrackPlay, tracks: { playingTrack = {} } = {} } = this.props
        if(playingTrack.id !== track.id) {
            onTrackPlay(track)        
        }        
    }    
    render(){        
        const { tracks, tracks: { list = [], playingTrack={}} = {}} = this.props   
        if(list.length === 0 && !tracks.isFetching) {
            return(
                <div>
                    <h1 className="uk-position-center uk-heading-line uk-text-center">
                        <span>No Track Found</span>
                    </h1>
                </div>
            )
        }
        return (            
            <div className='uk-container uk-padding-large uk-padding-remove-top'>
                <div 
                    ref={this.paneDidMount} 
                    className="uk-child-width-1-3@s uk-child-width-1-5@m uk-grid-small uk-grid-match tracks-list" 
                    uk-grid="true"
                >
                {       
                    list && list.map((track,i) => { 
                        const { id, title, reposts_count, likes_count, duration, artwork_url, user: { avatar_url, username } = {} } = track
                        return (   
                            <TrackCard  
                                key={`track_card_${i}`} 
                                id={id}
                                isSelected={playingTrack.id === id}
                                isSelectedTrackPaused={!playingTrack.isPlaying}                                    
                                title={title}
                                artist={username}
                                avatar={avatar_url}
                                duration={duration}
                                likesCount={likes_count}
                                repostsCount={reposts_count}
                                imageUrl={artwork_url || avatar_url}
                                onClick={()=>this.onClick(track)}                                                                    
                            />                                    
                        )
                    })
                }                                     
                </div>                
                <Loader isLoading={tracks.isFetching} />                        
                <BottomScrollListener onBottom={this.onPageEnd} />
            </div>                                              
            )
    }

}

function mapStateToProps(state) {
    return {
        configuration: state.configuration,
        tracks: state.tracks    
    }
}

export default withRouter(connect(mapStateToProps)(Home))