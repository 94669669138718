import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import Redbox from 'redbox-react'
import serviceConfig from '../serviceConfig'
import configureStore from './common/redux/configureStore'
import App from './common/containers/App'
import './scss/main.scss'

const store = configureStore({ configuration: serviceConfig })
const rootEl = document.getElementById('app')

render( 
  <AppContainer errorReporter={Redbox}>
    <App store={store}/>
  </AppContainer>,
  rootEl
)

if (module.hot) {
  module.hot.accept('./common/containers/App', () => {
    render(
      <AppContainer errorReporter={Redbox}>
        <App store={store} />
      </AppContainer>,
      rootEl,
    )
  })
}