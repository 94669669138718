import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise'
import rootReducer from './reducers'

const middleware = []

middleware.push(thunkMiddleware)
middleware.push(promiseMiddleware)

const createStoreWithMiddleware = compose(
  applyMiddleware(...middleware),
  window.devToolsExtension ? window.devToolsExtension() : (f) => f,
)(createStore)

const configureStore = (initialState) => {
  return createStoreWithMiddleware(rootReducer, initialState)
}

export default configureStore
