import axios from 'axios'

const fetchTracksApi = (url, params) => {
    return axios
      .get(`${url}`, {
        params
      })  
      .catch(error => {
        throw { message: error.message }
      })
}

export {
    fetchTracksApi
}