import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Ids } from '../../../constants'

const Controlls = ({onRepeat, volume, onVolumeChange}) => {
    return(
        <div className="uk-flex">
            <div className="repeat-button">
                <div id={Ids.repeatBtn} className="disabled-btn" onClick={onRepeat}></div>
            </div>
            <div className="uk-visible@m"> 
                <div className="volume-controller-container">
                    <div className={classNames({
                        'volume-control-button': volume !== 0,
                        'volume-control-button-muted': volume === 0
                    })}></div>                    
                    <div className="volume-overlay">
                        <input 
                            className="volume-control-trans volume-controller" 
                            type="range" 
                            onChange={onVolumeChange} 
                            id={Ids.volumeController} 
                            min="0" 
                            max="1" 
                            step="0.01" 
                            value={`${volume}`}  
                        />                                
                    </div>
                </div>                       
            </div>
        </div>        
    )
}

Controlls.defaultProps = {
    volume: 1
}

Controlls.propTypes = {
    onRepeat: PropTypes.func.isRequired,
    volume: PropTypes.number,
    onVolumeChange: PropTypes.func.isRequired
}

export default Controlls