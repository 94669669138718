import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import SiteRouter from '../SiteRouter'

const App = ({ store }) => {  
  return (
    <Router>
      <Provider store={store}>
        <SiteRouter />
      </Provider>
    </Router>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
}

export default App
