import { createAction } from 'redux-actions'
import { getServiceBase, getClientId } from '../configuration/selectors'
import { AppName } from '../../../constants'
import { fetchTracksApi } from '../../../api'
import { getNextTracks } from './selector'

const namespace = `${AppName}/tracks`
export const REQUEST_TRACKS = `${namespace}/REQUEST_TRACKS`
export const REVEIVE_TRACKS = `${namespace}/RECEIVE_TRACKS`
export const REVEIVE_NEXT_TRACKS = `${namespace}/REVEIVE_NEXT_TRACKS`
export const REVEIVE_FAILED_TRACKS = `${namespace}/REVEIVE_FAILED_TRACKS`
export const UPDATE_PLAYBACK_TRACKS = `${namespace}/UPDATE_PLAYBACK_TRACKS`
export const UPDATE_PLAYBACK_TRACKS_PLAYED = `${UPDATE_PLAYBACK_TRACKS}/PLAYED`
export const UPDATE_PLAYBACK_TRACKS_PAUSED = `${UPDATE_PLAYBACK_TRACKS}/PAUSED`

const requestTracks = createAction(REQUEST_TRACKS)
const receiveTracks = createAction(REVEIVE_TRACKS)
const receiveNextTracks = createAction(REVEIVE_NEXT_TRACKS)
const receiveFailedTracks = createAction(REVEIVE_FAILED_TRACKS)
const updatedPlaybackTrack = createAction(UPDATE_PLAYBACK_TRACKS)
const triggeredTrackPlay = createAction(UPDATE_PLAYBACK_TRACKS_PLAYED)
const triggerTrackPaused = createAction(UPDATE_PLAYBACK_TRACKS_PAUSED)

export const fetchTracks = query => {
  return (dispatch, getState) => {
    const state = getState()
    const serviceBase = getServiceBase(state)
    const client_id = getClientId(state)
    dispatch(requestTracks())
    return fetchTracksApi(`${serviceBase}?linked_partitioning=1&limit=30&offset=0&client_id=${client_id}&q=${query}`)
    .then(response => {
      dispatch(receiveTracks(response))
    })
    .catch(()=>{
      dispatch(receiveFailedTracks())
    })
  }
}

export const fetchNextTracks = () => {        
  return (dispatch, getState) => {
    const state = getState()
    const nextTracksUrl = getNextTracks(state)
    if(nextTracksUrl) {
      dispatch(requestTracks())
      return fetchTracksApi(`${nextTracksUrl}`)
      .then(response => {
        dispatch(receiveNextTracks(response))
      })
      .catch(()=>{
        dispatch(receiveFailedTracks())
      })
    }      
  }
}

export const currentlyPlayingTrack = (track) => {        
  return (dispatch) => {
    dispatch(updatedPlaybackTrack(track))    
  }
}

export const onTrackPlayed = () => {        
  return (dispatch) => {
    dispatch(triggeredTrackPlay())    
  }
}

export const onTrackPaused = () => {        
  return (dispatch) => {
    dispatch(triggerTrackPaused())    
  }
}