import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({isLoading}) => {
    
    return(
        isLoading? <span 
            className="uk-padding-large uk-flex uk-flex-center uk-flex-middle"
            uk-spinner="ratio: 1.0"
        ></span> : null
    )
}

Loader.defaultProps = {
    isLoading: false
}

Loader.propTypes = {
    isLoading: PropTypes.bool
}

export default Loader