import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)    
  }

  handleChange(event) {
    const { onSearch } = this.props
    const { target:{ value } = {}} = event
    this.setState({value: value})
    onSearch(value)
  }

  render() {
    const {text, logo, isFetching } = this.props
    return (    
      <nav uk-sticky="uk-navbar-sticky;" className="uk-margin uk-margin-small-bottom uk-background-primary uk-light uk-navbar-item position-z-index-2" uk-navbar="true">
        <div className="uk-navbar-left">
          <div className="uk-navbar-left">
            <img data-src={`/images/${logo}`} width="75px" height="75px" alt="" uk-img="true"></img>
            <a className="uk-visible@s uk-navbar-item uk-logo" href="/">{text}</a>          
          </div> 
        </div>        
        <div className="uk-navbar-item uk-navbar-right">     
          <div className="uk-override">
            <form className="uk-width-auto uk-search uk-search-navbar uk-flex" onSubmit={(e)=>{e.preventDefault()}}>                
                { !isFetching ? 
                      <div className="uk-visible@s uk-position-center-left-out uk-overlay" uk-icon="icon: search" uk-search-icon={true}></div>
                      : <div>
                          <span 
                              className="uk-position-center-left-out uk-overlay uk-flex uk-flex-center uk-flex-middle"
                              uk-spinner="ratio: 1.0"
                          ></span>
                        </div>                      
                }
                <input className="uk-search-input" type="search" placeholder="Search..." value={this.state.value} onChange={this.handleChange}/>
            </form>
        </div>
        </div>      
      </nav>
    )
  }
}

Header.defaultProps = {
  logo: 'logo.png',
  isFetching: false,
  onSearch: ()=>{}
}

Header.propTypes = {
  logo: PropTypes.string,
  text: PropTypes.string,
  isFetching: PropTypes.bool,
  onSearch: PropTypes.func,
}
export default Header