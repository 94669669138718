import React from 'react'
import PropTypes from 'prop-types'

const TrackInfo = ({avatar, title, artist}) => {
    return(
        <div className="uk-visible@m uk-width-1-6">
            <div className="uk-flex card-body trackInfo">
                <div className="track-avatar">
                    <img className='card-avatar' src={avatar} />
                </div>   
                <div className='card-details'>
                    <marquee behavior="scroll" direction="left" scrollamount="3">
                        <div className="uk-text-muted" alt={title}>{title}</div>
                        <a className="uk-link-text uk-text-primary"><u>{artist}</u></a>  
                    </marquee>
                    
                </div>                         
            </div> 
        </div>
    )
}

TrackInfo.propTypes = {    
    avatar: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired    
}

export default TrackInfo