import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getActiveDocumentElement } from './utils'
import { Ids } from '../../constants'
import TrackInfo from './TrackInfo';
import PlaybackControlls from './PlaybackControlls';
import ProgressSlider from './ProgressSlider';
import Duration from './Duration';
import Controlls from './Controlls';

class AudioPlayer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPlaying: false,
            slider: {},
            volumeController: {},
            duration: {
                total: 0,
                current: 0,
                percentComplete: 1 
            }
        }
        this.onPlay = this.onPlay.bind(this)
        this.onNext = this.onNext.bind(this)
        this.onPrevious = this.onPrevious.bind(this)
        this.onPause = this.onPause.bind(this)
        this.onRepeat = this.onRepeat.bind(this)
        this.onSeek = this.onSeek.bind(this)
        this.onVolumeChange = this.onVolumeChange.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    componentDidMount() {
        this._player = document.getElementById(Ids.audioPlayer) 
        const slider = document.getElementById(Ids.audioSlider)      
        const volumeController = document.getElementById(Ids.volumeController)
        this.addAudioEventListeners(this._player)
        this.setState({
            slider: slider,
            volumeController: volumeController
        }, ()=> this.onPlay())     
        window.addEventListener("keydown", this.onKeyDown, false);   
    }
    
    componentWillUnmount () {
        window.removeEventListener("keydown", this.onKeyDown);   
    }

    onKeyDown = (e) => {
        const audio = this._player
        switch (event.keyCode) {
            case 32: //SpaceBar  
                if(getActiveDocumentElement() !== 'input')
                {   
                    e.preventDefault()
                    if (audio.duration > 0 && !audio.paused) {
                        this.onPause()                    
                    } else {
                        this.onPlay();                    
                    }
                }                
                break;
        }
    }

    addAudioEventListeners = (aud) => {
        if(!aud) {
            return
        }
        aud.ontimeupdate = () => {  
            if(aud.error && aud.error.code) {
                this.onNext()
            }
            if(aud) {
                this.setState({
                    duration: {
                        total: aud.duration,
                        current: (aud.duration - aud.currentTime),
                        percentComplete: parseInt((aud.currentTime/aud.duration)*100) || 1
                    }
                })
            }
            }            
    }

    componentDidUpdate(prevProps) {
        if(this.props.source !== prevProps.source) {            
            this._player.src = this.props.source
            this._player.load()
            this.onPlay()
        }
    }
    
    onPlay = () => {
        const { onPlayed } = this.props
        this.setState({
            isPlaying: true
        }, ()=> {            
            this._player.play()
            onPlayed()            
            this._player.onended = this.resetPlayback               
        })    
    }

    resetPlayback = () => {
        const { onEnded } = this.props
        this.setState({
            isPlaying: false
        }, () =>{ 
            this._player && this._player.pause()
            if(onEnded) {
                onEnded()
            }            
         })
    }

    onRepeat = () => { 
        const repeatBtn = document.getElementById(Ids.repeatBtn)
        if(repeatBtn.classList.contains('disabled-btn')) {
            this.enableRepeat()
        } else {
            this.disableRepeat()
        }
    }

    enableRepeat = () => {
        const repeatBtn = document.getElementById(Ids.repeatBtn)
        repeatBtn.classList.add('enabled-btn')
        repeatBtn.classList.remove('disabled-btn')
        this._player.loop = true
    }

    disableRepeat = () => {
        const repeatBtn = document.getElementById(Ids.repeatBtn)
        repeatBtn.classList.add('disabled-btn')
        repeatBtn.classList.remove('enabled-btn')
        this._player.loop = false
    }

    onVolumeChange = () => {
        this._player.volume = this.state.volumeController.value
    }

    onSeek = () => {                
        this._player.currentTime = parseInt(this.state.duration.total*(this.state.slider.value/100))
    }
    
    onPause = () => {
        const { onPaused } = this.props
        this.setState({
            isPlaying: false
        }, ()=> { 
            this._player.pause()
            onPaused()
        })
    }
    
    onNext = () => {
        const { onNext } = this.props
        this.disableRepeat()
        onNext()
    }
    
    onPrevious = () => {
        const { onPrevious } = this.props
        this.disableRepeat()
        onPrevious()
    }

    audioPlayer = (audioSource) => {
        return (
            <audio id={Ids.audioPlayer}>            
                <source src={audioSource} type={Ids.audioType} />
                Your browser does not support the audio element.
            </audio>)
    }
    
    render() {
        const {
            source,
            avatar,
            title,
            artist
        } = this.props
        const { _player: { volume } = {} } = this
        return(
            <div className="audio-player uk-padding-small uk-container">
                { this.audioPlayer(source) }
                <div className="uk-flex uk-flex-center">
                    <TrackInfo 
                        avatar={avatar}
                        title={title}
                        artist={artist}
                    />
                    <PlaybackControlls 
                        isPlaying={this.state.isPlaying} 
                        onPrevious={this.onPrevious} 
                        onPause={this.onPause} 
                        onPlay={this.onPlay} 
                        onNext={this.onNext}                     
                    />
                    <ProgressSlider 
                        percentComplete={this.state.duration.percentComplete}
                        onSeek={this.onSeek}
                    />
                    <Duration 
                        currentTime={this.state.duration.current}
                    />    
                    <Controlls 
                        onRepeat={this.onRepeat}
                        volume={volume}
                        onVolumeChange={this.onVolumeChange}                        
                    />                                    
                </div>                
            </div>
            
        )
    }
}

AudioPlayer.propTypes = { 
    source: PropTypes.string.isRequired,   
    avatar: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,    
    onPrevious: PropTypes.func.isRequired,    
    onNext: PropTypes.func.isRequired,    
    onPaused: PropTypes.func.isRequired,    
    onEnded: PropTypes.func.isRequired,    
    onPlayed: PropTypes.func.isRequired,    
}

export default AudioPlayer