import React from 'react'
import PropTypes from 'prop-types'
import { secondsToTime } from '../utils'

const Duration = ({currentTime}) => {
    return(
        <div className="duration">
            <div className="">{secondsToTime(currentTime)}</div>
        </div>
    )
}


Duration.propTypes = {    
    currentTime: PropTypes.number.isRequired,    
}

export default Duration