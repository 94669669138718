import React from 'react'
import PropTypes from 'prop-types'
import { Ids } from '../../../constants'

const ProgressSlider = ({percentComplete, onSeek}) => {
    return(
        <div className="slidecontainer">
            <input 
                type="range" 
                min="1" 
                max="100" 
                value={`${percentComplete}`} 
                className="slider" 
                id={Ids.audioSlider}
                onChange={onSeek}
            />
        </div>
    )
}

ProgressSlider.propTypes = {    
    percentComplete: PropTypes.number.isRequired,
    onSeek: PropTypes.func.isRequired      
}

export default ProgressSlider