export const handleReceiveFail = (state = {}, action = {}) => {
    const { payload } = action
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      isError: true,
      error: payload,
    }
}

export const handleRequest = (state = {}) => ({
    ...state,
    isFetching: true,
    didInvalidate: false,
    isError: false,
})