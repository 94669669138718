const flattenAssets = tracks => {
  const flat = {}
  for (var i = 0; i < tracks.length; i++) {
    const { id } = tracks[i]
    flat[id] = tracks[i]
  }
  return flat
}

export const handleReceiveTracks = (state = {}, action = {}) => {
  const { payload: { data: { collection = [], next_href } } } = action
  return {
    ...state,
    list: collection,
    flatList: flattenAssets(collection),
    next_href,
    isFetching: false,
    didInvalidate: false,
    hasFetched: true,    
    isError: false
  }
}

export const handleReceiveNextTracks = (state = {}, action = {}) => {
  const { payload: { data: { collection = [], next_href } } } = action
  const extendedTracks = {
    ...state.flatList,
    ...flattenAssets(collection)
  }
  return {
    ...state,
    list: [...state.list, ...collection],
    flatList: extendedTracks,
    next_href,
    isFetching: false,
    didInvalidate: false,
    hasFetched: true,    
    isError: false
  }
}

export const handleUpdatePlaybackTracks = (state = {}, action = {}) => {
  const { payload } = action
  return {
    ...state,
    playingTrack: payload 
  }
}

export const handleUpdatePlaybackTracksPlayed = (state = {}) => {
  
  return {
    ...state,
    playingTrack: {
      ...state.playingTrack,
      isPlaying: true
    } 
  }
}

export const handleUpdatePlaybackTracksPaused = (state = {}) => {  
  return {
    ...state,
    playingTrack: {
      ...state.playingTrack,
      isPlaying: false
    } 
  }
}