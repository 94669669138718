import { createAction } from 'redux-actions'
import { AppName } from '../../../constants'
import { fetchTracks } from '../tracks/actions'

const namespace = `${AppName}/search`
export const SEARCH_TRACKS = `${namespace}/SEARCH_TRACKS`

const searchTrack = createAction(SEARCH_TRACKS)

export const searchTracks = value => {
  return (dispatch) => {
    dispatch(searchTrack())
    dispatch(fetchTracks(value))
  }
}