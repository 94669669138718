const secondsToTime = (timeSeconds) => {
    if(!timeSeconds) {
      return "00:00"
    }
    let hours   = Math.floor(timeSeconds / 3600)
    let minutes = Math.floor((timeSeconds - (hours * 3600)) / 60)
    let seconds = parseInt((timeSeconds - (hours * 3600) - (minutes * 60)))
    let time = ""

    if (hours != 0) {
      time = hours+":"
    }
    if (minutes != 0 || time !== "") {
      minutes = (minutes < 10) ? "0"+minutes : String(minutes)
      time += minutes+":"
    } 
    if (time === "") {
      time = "00:"
      time += (seconds < 10) ? "0"+seconds : String(seconds)
    }
    else {
      time += (seconds < 10) ? "0"+seconds : String(seconds)
    }
    return time
}

const getActiveDocumentElement = () => {
  return document.activeElement.tagName.toLowerCase()
}


export {
    secondsToTime,
    getActiveDocumentElement
}