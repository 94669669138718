import React from 'react'
import PropTypes from 'prop-types'

const PlaybackControlls = ({isPlaying, onPrevious, onPause, onPlay, onNext}) => {
    return(
        <div className="">
            <div className={'previous-button'} onClick={onPrevious}></div>
            {
                isPlaying ? 
                    <div className={'pause-button'} onClick={onPause}></div>
                    : <div className={'play-button'} onClick={onPlay}></div>
            }
            <div className={'next-button'} onClick={onNext}></div>
        </div> 
    )
}

PlaybackControlls.propTypes = {    
    isPlaying: PropTypes.bool.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired   
}

export default PlaybackControlls