import { handleActions as reducer } from 'redux-actions'
import {
  REQUEST_TRACKS,
  REVEIVE_TRACKS,
  REVEIVE_NEXT_TRACKS,
  REVEIVE_FAILED_TRACKS,
  UPDATE_PLAYBACK_TRACKS,
  UPDATE_PLAYBACK_TRACKS_PLAYED,
  UPDATE_PLAYBACK_TRACKS_PAUSED
} from './actions'
import { handleRequest, handleReceiveFail } from '../../utils'
import {
  handleReceiveTracks,
  handleReceiveNextTracks,
  handleUpdatePlaybackTracks,
  handleUpdatePlaybackTracksPaused,
  handleUpdatePlaybackTracksPlayed
} from './utils'

const handleRequestTracks = state => {
  return {
    ...state,
    ...handleRequest(state)
  }
}

const handleReceivedTracks = (state, action) => {
  return {
    ...handleReceiveTracks(state, action)
  }
}

const handleReceivedNextTracks = (state, action) => {
  return {
    ...handleReceiveNextTracks(state, action)
  }
}

const handleReceivedFailedTracks = (state, action) => {
  return {
    ...handleReceiveFail(state, action)
  }
}

const handleUpdatedPlaybackTracks = (state, action) => {
  return {
    ...handleUpdatePlaybackTracks(state, action)
  }
}

const handleTrackOnPlay = (state, action) => {
  return {
    ...handleUpdatePlaybackTracksPlayed(state, action)
  }
}

const handleTrackOnPause = (state, action) => {
  return {
    ...handleUpdatePlaybackTracksPaused(state, action)
  }
}

export default reducer(
  {
    [REQUEST_TRACKS]: handleRequestTracks,
    [REVEIVE_TRACKS]: handleReceivedTracks,    
    [REVEIVE_NEXT_TRACKS]: handleReceivedNextTracks,    
    [REVEIVE_FAILED_TRACKS]: handleReceivedFailedTracks,    
    [UPDATE_PLAYBACK_TRACKS]: handleUpdatedPlaybackTracks,    
    [UPDATE_PLAYBACK_TRACKS_PLAYED]: handleTrackOnPlay,
    [UPDATE_PLAYBACK_TRACKS_PAUSED]: handleTrackOnPause
  },
  {}
)