import React from 'react'
import PropTypes from 'prop-types'
import AudioPlayer from '../AudioPlayer'

const Footer = ({
    title,
    artist,
    stream_url,
    client_id,
    playNextTrack,
    onTrackEnd,
    playPreviousTrack,
    avatar,
    onTrackPaused,
    onTrackPlayed
}) => {
    return(
        stream_url ? <div className="footerholder position-z-index-2">
            <div className="audio-player">
                <AudioPlayer
                    source={`${stream_url}?client_id=${client_id}`}
                    onEnded={onTrackEnd}
                    onNext={playNextTrack}
                    onPrevious={playPreviousTrack}
                    onPaused={onTrackPaused}
                    onPlayed={onTrackPlayed}
                    title={title}
                    artist={artist}
                    avatar={avatar}
                />
            </div>
        </div> : null
    )
}

Footer.defaultProps = {
    title: '',
    artist: '',        
    avatar: '',
    playNextTrack: ()=>{},
    onTrackEnd: ()=>{},
    playPreviousTrack: ()=>{},    
    onTrackPaused: ()=>{},
    onTrackPlayed: ()=>{}
}
 
Footer.propTypes = {   
    title: PropTypes.string,
    artist: PropTypes.string,
    avatar: PropTypes.string,
    playNextTrack: PropTypes.func,
    onTrackEnd: PropTypes.func,
    playPreviousTrack: PropTypes.func,    
    onTrackPaused: PropTypes.func,
    onTrackPlayed: PropTypes.func,
    stream_url: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
    
}

export default Footer