const AppName = 'Soundify'
const SearchInterval = 340
const Ids = {
    audioPlayer: "soundCloud_Audio_Player",
    audioType: "audio/mpeg",
    audioSlider: "soundCloud_Slider",
    repeatBtn: "soundCloud_repeatBtn",
    volumeController: "soundCloud_volumeController"
}
export {
    Ids,
    AppName,
    SearchInterval
}